body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-group label{
  pointer-events: none;
  z-index: 0;
}
.form-group.file-upload label{
  pointer-events: unset;
}


.sd-container{
  border: 1px solid #5d7083;
  border-radius: 3px;
}
.sd-container.sd-radio, .sd-container.sd-file, .sd-textarea{
  margin: 0 !important;
  background: #F2FAFF;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #5d7083;
}
.sd-radio-container > .form-group{
  margin: 0 !important;
}

.form-check-label{
  overflow: unset !important;
  text-overflow: unset !important;
  white-space: unset !important;
  line-height: 1.5rem;
  padding-top: .1rem;
}

.input-group .form-control, .input-group .form-control:focus, .input-group .input-group-text {
  background: #F2FAFF;
}
